import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { curationActions } from '../../../actions';

export function useCurationController() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('LISTING');
  const { ideasStepsFlux } = useSelector(state => state.domains);
  const { loading, filters, ideas, paging } = useSelector((state) => state.curation);
  const { occupationArea } = useSelector(state => state.businessUnitProps);
  const {
    paging: {
      sort: challengeSort,
      filter: challengeFilter
    }
  } = useSelector(state => state.challenge);

  useEffect(() => {
    if (ideasStepsFlux) {
      const ideasStepsFluxFiltered = ideasStepsFlux.map(item => ({
        label: item.name,
        value: item._id,
        checked: true
      }));
  
      const selectAllOptions = {
        label: t('selecionar_todos'),
        value: 'SELECT_ALL',
        checked: true,
      };
  
      dispatch(curationActions.loadAllSteps([selectAllOptions, ...ideasStepsFluxFiltered]));
    }
  }, [ideasStepsFlux, dispatch]);

  useEffect(() => {
    dispatch(curationActions.getStepFluxIdeas({
      statusCode: [],
      stepsIdeas: ideasStepsFlux.map(item => item._id),
      challenges: [],
      occupationAreas: [],
      page: paging.page,
      limit: paging.limit,
      sort: paging.sort,
    }));

    dispatch(curationActions.getChallenges(1, challengeSort, challengeFilter, 100));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(curationActions.clearText());
      dispatch(curationActions.clearCurationChallengesFilter());
      dispatch(curationActions.clearCurationStepsFilter());
      dispatch(curationActions.clearCurationPaging());
    };
  }, []);

  const occupationAreaSelected = useMemo(() => {
    return occupationArea.filter(item => item.checked && item.value !== 'SELECT_ALL').map(({ value }) => value);
  }, [occupationArea]);

  const stepsSelected = useMemo(() => {
    return filters.steps.filter(({ checked, value }) => checked && value !== 'SELECT_ALL').map(({ value }) => value);
  }, [filters.steps]);

  const challengeSelected = useMemo(() => {
    return filters.challenges.filter(({ checked, value }) => checked && value !== 'SELECT_ALL').map(({ value }) => value);
  }, [filters.challenges]);

  function searchIdea(query) {
    if (selectedTab === "LISTING") {
      dispatch(curationActions.changeSearch(query))
      dispatch(curationActions.getStepFluxIdeas({
      ...filters,
      statusCode: [],
      stepsIdeas: stepsSelected,
      challenges: challengeSelected,
      occupationAreas: occupationAreaSelected,
      page: paging.page,
      limit: paging.limit,
      sort: paging.sort,
      query: query
    }));
    } else {
      dispatch(curationActions.changeSearch(query))
      dispatch(curationActions.loadIdeas({...filters, searchText: query}, {
        ...paging,
      }, occupationArea));
    }
  }

  function handleChangePage(_event, page) {
    let newPage = page + 1;

    if (selectedTab !== "LISTING") {
      dispatch(curationActions.loadIdeas(filters, {
        ...paging,
        page: newPage,
      }, occupationArea))
    } else {
      dispatch(curationActions.getStepFluxIdeas({
      ...filters,
      statusCode: [],
      stepsIdeas: stepsSelected,
      challenges: challengeSelected,
      occupationAreas: occupationAreaSelected,
      page: newPage,
      limit: paging.limit,
      sort: paging.sort,
      query: paging.query
    }))
    }
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value;

    if (selectedTab !== "LISTING") {
      dispatch(curationActions.loadIdeas(filters, {
        ...paging,
        limit
      }, occupationArea))
    } else {
      dispatch(curationActions.getStepFluxIdeas({
      ...filters,
      statusCode: [],
      stepsIdeas: stepsSelected,
      challenges: challengeSelected,
      occupationAreas: occupationAreaSelected,
      page: 1,
      limit,
      sort: paging.sort,
      query: paging.query
    }))
    }
  }

  return {
    ideas,
    loading,
    selectedTab,
    stepsSelected,
    challengeSelected,
    occupationAreaSelected,
    setSelectedTab,
    searchIdea,
    handleChangePage,
    handleChangeRowsPerPage
  }
}