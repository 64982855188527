import React from 'react';
import { styled } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const Typography = styled(MuiTypography)(({ theme, status }) => ({
  color: theme.palette.neutrals.high.main,
  fontSize: 11,
  fontWeight: 600,
  textTransform: 'uppercase',
  backgroundColor: status?.color || status?. labelColor,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1),
  width: 'fit-content'
}));


export function StatusIdea({ status }) {
  return <Typography status={status}>{status?.name}</Typography>;
}