import React from 'react';

import Box from '@material-ui/core/Box';
import MuiAvatar from '@material-ui/core/Avatar';
import MuiTypography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  background: theme.palette.primary.light,
  color: theme.palette.primary.main,
  fill: theme.palette.primary.main,
}));

const Typography = styled(MuiTypography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.neutrals.low.main
}));

export function AvatarIconWithText({ icon, variant, text, style }) {
  return (
    <Box display="flex" alignItems="center">
      <Avatar style={style}>{icon}</Avatar>
      <Typography variant={variant ?? 'h3'}>{text}</Typography>
    </Box>
  );
}