import React, { useEffect } from 'react';
import { Container, makeStyles, Grid, Card } from '@material-ui/core';
import {
  BackNavAdmin,
  ArticleCreateCard,
  ArticleImageCard,
  ArticleProgressCard,
  ArticleAuthors,
  ArticleStatus,
  ArticleInnovationCard
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { articleActions } from '../../actions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  card: {
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
    borderRadius: 8
  },
  fixed: {
    position: 'sticky',
    boxSizing: 'border-box',
    top: '76px',
  },
}));

function ArticleEditPage() {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    articleDetails,
    newArticle: { title, description, thumbnail, banner },
    newArticle
  } = useSelector((state) => state.articlesReducer);

  useEffect(() => {
    dispatch(articleActions.getArticleById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (articleDetails) {
      dispatch(articleActions.loadArticleDetails(articleDetails));
    }
  }, [dispatch, articleDetails]);

  const shouldDisable = (
    !!title?.length &&
    description?.length > 100
  );

  const RenderImageCard = ({ type, file }) => {
    return (<ArticleImageCard
      isEdit
      type={type}
      image={file?.file ? file : { readableSize: file?.size || '', name: file?.fileName || '', preview: file?.url || '' }}
    />)
  }

  return (
    <>
      <BackNavAdmin title={t('editar_inovacao')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ArticleCreateCard isEdit title={title} description={description} />
              </Grid>

              <ArticleInnovationCard title={title} description={description} />

              <Grid item xs={12}>
                <Card elevation={0} className={classes.card}>
                  <RenderImageCard type='thumbnail' file={thumbnail} />
                  <RenderImageCard type='banner' file={banner} />
                </Card>
              </Grid>

              <Grid item xs={12}>
                <ArticleAuthors isEdit />
              </Grid>

              <Grid item xs={12}>
                <ArticleStatus isEdit />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.fixed}>
              <ArticleProgressCard
                isEdit
                article={newArticle}
                shouldDisable={!shouldDisable}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { ArticleEditPage };
