import React from 'react';
import { Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typography, IconButton, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 20.25,
    fontWeight: 'bold',
    marginLeft: 10,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, goBack, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap="1rem" >
          {goBack && (
            <IconButton
              aria-label="goBack"
              color="primary"
              onClick={goBack}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography className={classes.title}>{children}</Typography>
        </Box>
        {onClose && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </MuiDialogTitle>
  );
});

function DialogCustomTitle(props) {
  return <DialogTitle {...props} />;
}

export { DialogCustomTitle };
