import React, { useEffect } from 'react';
import { Container, makeStyles, Grid, Card } from '@material-ui/core';
import {
  BackNavAdmin,
  ArticleCreateCard,
  ArticleImageCard,
  ArticleProgressCard,
  ArticleAuthors,
  ArticleStatus,
  ArticleInnovationCard
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { articleActions } from '../../actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  card: {
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
    borderRadius: 8
  },
  fixed: {
    position: 'sticky',
    boxSizing: 'border-box',
    top: '76px',
  },
}));

function ArticleNewPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    newArticle: { title, description, thumbnail, banner },
    newArticle,
    loading,
    articleQuestions
  } = useSelector((state) => state.articlesReducer);

  useEffect(() => {
    dispatch(articleActions.getArticleQuestions());
  }, [dispatch]);

  useEffect(() => {
    if (articleQuestions) {
      dispatch(articleActions.loadArticleQuestions(articleQuestions.map((item) => ({
        _id: item._id,
        question: item.question,
        answer: item?.answer ?? '',
        isSelect: item?.isSelect,
        valuesToSelect: item?.valuesToSelect,
        attachmentRequired: item?.attachmentRequired
      }))));
    }
  }, [dispatch, articleQuestions]);

  const shouldDisable = (
    !!title?.length &&
    description?.length > 100
  );

  return (
    <>
      <BackNavAdmin title={t('nova_inovacao')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ArticleCreateCard title={title} description={description} />
              </Grid>

              <ArticleInnovationCard title={title} description={description} />

              <Grid item xs={12}>
                <Card elevation={0} className={classes.card}>
                  <ArticleImageCard type="thumbnail" image={thumbnail} />
                  <ArticleImageCard type="banner" image={banner} />
                </Card>
              </Grid>

              <Grid item xs={12}>
                <ArticleAuthors />
              </Grid>

              <Grid item xs={12}>
                <ArticleStatus />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <div className={classes.fixed}>
              <ArticleProgressCard
                article={newArticle}
                loading={loading}
                shouldDisable={!shouldDisable}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { ArticleNewPage };
