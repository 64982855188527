import { Box, Card, makeStyles, Typography, useTheme } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import React from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { articleActions } from '../../actions';
import { history } from '../../helpers';
import { useWindowSize } from '../../hooks';
import ImagineSlider from '../Shared/ImagineSlider';
import { ArticleCard } from './ArticleCard';
import { ArticleNextArrow, ArticlePrevArrow } from './ArticleSliderOptions';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    marginTop: theme.spacing(2)
  },
  cardTitle: {
    color: theme.palette.neutrals.low.main,
  },
  textSeeAll: {
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.palette.neutrals.low.light,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  seeAllContainer: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.auxiliary.beige.main
  }
}));

function HighLightArticleList() {
  const dispatch = useDispatch();
  const { highlightArticles } = useSelector(state => state.articlesReducer);
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const [width] = useWindowSize();

  const { lg, md, sm } = theme.breakpoints.values;

  const [isLgWindow, isMdWindow, isSmWindow] = useMemo(() => {
    return [width <= lg && width > md, width <= md && width > sm, width <= sm];
  }, [width, md, sm]);

  const slidesNumberToRender = isLgWindow ?
    (highlightArticles.length >= 3 ? 3 : highlightArticles.length)
    : isMdWindow ? 2 : isSmWindow ? 1 : highlightArticles.length;

  useEffect(() => {
    if (!highlightArticles || highlightArticles.length === 0) {
      dispatch(articleActions.getHighlightArticles());
    }
  }, [dispatch, highlightArticles]);


  return (
    <>
      <Card className={classes.row} elevation={0}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" className={classes.cardTitle}>
            {t('acesse_projetos_da_#InovaçãoAncar_no_nosso_mural')}
          </Typography>
        </Box>

        <Box className={classes.seeAllContainer}>
          <Typography className={classes.textSeeAll} onClick={() => history.push('/articles')}>
            {t('ver_todas')}
          </Typography>
          <KeyboardArrowDown />
        </Box>
      </Card>
      <ImagineSlider
        settings={{
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: slidesNumberToRender,
          slidesToScroll: 1,
          adaptiveHeight: true,
          autoplay: true,
          autoplaySpeed: 7000,
          nextArrow: <ArticleNextArrow />,
          prevArrow: <ArticlePrevArrow />
        }}
      >
        {highlightArticles.map((item, index) => (
          <ArticleCard
            key={index}
            article={item}
            description={item.description}
            title={item.title}
            image={item?.thumbnail?.url}
            link={`/article/detail/${item._id}`}
            ups={item?.ups}
            isFeed={true}
            index={index}
          />
        ))}
      </ImagineSlider>
    </>
  );
}

export { HighLightArticleList };
