import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Box, Button, CardContent, IconButton, CardActions } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import { history, truncate } from '../../helpers';
import { CardMedia } from '@material-ui/core';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import imageCard from '../../assets/imgs/imagem_padrao_card_desafio.png';
import { articleActions } from '../../actions';
import { Add, More } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    '& .MuiTypography-root': {
      textTransform: 'none',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.primary.dark,
  },
  description: {
    color: theme.palette.auxiliary.beige.main,
    fontSize: 10,
  },
  media: {
    paddingTop: '46.25%',
    width: '100%'
  },
  upsFeedContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.neutrals.low.light,
    borderTop: `1px solid ${theme.palette.neutrals.high.dark}`
  },
  upsInnovationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    gap: 10,
    color: theme.palette.neutrals.low.light
  },
  addIcon: {
    color: theme.palette.primary.main,
    fontSize: 12
  },
  seeAllContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    '&:hover': {
      color: theme.palette.neutrals.high.dark,
      textDecoration: 'underline',
    },
  },
  textSeeAll: {
    fontSize: 12,
    cursor: 'pointer',
    color: theme.palette.auxiliary.beige.main,
  },
  cardContent: {
    height: 150,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    cursor: 'pointer'
  }
}));

function ArticleCard({ article, title, description, link, image, ups, isFeed = false, index, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userLogged = useSelector((state) => state.user);
  const { likeLoading } = useSelector((state) => state.articlesReducer);
  const { t } = useTranslation();

  function goToArticle() {
    history.push(link);
  }

  function handleUpArticle() {
    dispatch(articleActions.upArticle(article._id, index, isFeed));
  }

  return (
    <Card
      {...props}
      style={{ margin: 10 }}
      className={classes.root}
      elevation={0}
    >
      <CardMedia
        className={classes.media}
        image={image || imageCard}
        title={title}
      />
      <CardContent onClick={goToArticle} className={classes.cardContent}>
        <Box>
          <Typography className={classes.title}>
            {truncate(title, 40, '...')}
          </Typography>
          <Typography className={classes.description}>
            {truncate(description, 100, '...')}
          </Typography>
        </Box>
        <Box className={classes.seeAllContainer}>
          <Typography className={classes.textSeeAll} onClick={goToArticle}>
            {t('ver_mais')}
          </Typography>
          <Add className={classes.addIcon} />
        </Box>
      </CardContent>
      <CardActions className={classes.upsFeedContainer}>
        {userLogged._id !== ups?._id && (
          <IconButton
            color={ups?.some(({ _id }) => _id === userLogged._id) ? 'primary' : 'default'}
            onClick={handleUpArticle}
            size="small"
            style={{ fontSize: 14.22 }}
            disabled={likeLoading}
          >
            <ThumbUpAltIcon />
          </IconButton>
        )}
        <Typography variant="subtitle2">
          {ups?.length ?? 0}{ups?.length > 1 ? ' UPS' : ' UP'}
        </Typography>
      </CardActions>
    </Card>
  );
}

export { ArticleCard };
